<template>
  <b-modal centered
           :id="`registerSingleProd`"
           :title="`단일 상품 등록`"
           :ok-title="'등록'"
           @ok="addSingleProd"
           :cancel-title="'취소'"
           @hidden="fieldInit">
    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">
        바코드
      </label>
      <div class="col-sm-8">
        <input class="form-control" placeholder="바코드를 조회해주세요." readonly v-model="selectedBarcode.barcode"
               v-b-modal="'srchBarcodeInSingleProd'">
      </div>
      <div class="col-sm-2">
        <b-button style="width: 100%" variant="primary" v-b-modal="'srchBarcodeInSingleProd'">조회</b-button>
      </div>

      <b-modal centered
               :id="`srchBarcodeInSingleProd`"
               :title="`바코드 검색`"
               hide-footer>
        <srch-barcode-list :srch-key="srchCode"></srch-barcode-list>
      </b-modal>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">
        상품명
      </label>
      <div class="col-sm-10">
        <input class="form-control" placeholder="상품명을 입력해주세요." readonly
               :value="typeof selectedBarcode.product_name_doha !== 'undefined' ? selectedBarcode.product_name_doha.replaceAll('_', ' ') : selectedBarcode.product_name_doha">
      </div>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">
        이미지
      </label>
      <div class="col-sm-10">
        <img class="form-control"
             :src="typeof selectedBarcode.img_path !== 'undefined' && selectedBarcode.img_path !== '' ? selectedBarcode.img_path : require('@/assets/mcimages/no_img.png')">
      </div>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label" style="display: flex; align-items: center">
        가격
      </label>

      <div class="col-sm-5">
        <label class="form-label">원가격</label>
        <input class="form-control" placeholder="원가격을 입력해주세요." v-model="originPrice" v-numeric-only>
      </div>

      <div class="col-sm-5">
        <label class="form-label">할인가격</label>
        <input class="form-control" placeholder="할인가격을 입력해주세요." v-model="salePrice" v-numeric-only>
      </div>
    </div>

    <div class="row mb-2">
      <label class="col-sm-2 col-form-label">
        배지선택
      </label>
      <div class="col-sm-10 col-form-label">
        <div style="display: inline; margin-right: 10px">
          <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="limit" true-value="Y"
                 false-value="N" v-model="limit"/>
          <label for="limit">
            <img :src="require('@/assets/mcimages/marker_limit@3x.png')"
                 class="form-check-label" style="height: 16px;position: relative;top: -1.2px;"
                 for="limit"/>
          </label>
        </div>

        <div style="display: inline; margin-right: 10px">
          <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="manager" true-value="Y"
                 false-value="N" v-model="manager"/>
          <label for="manager">
            <img :src="require('@/assets/mcimages/marker_best@3x.png')"
                 class="form-check-label"
                 style=" height: 16px; position: relative; top: -1.2px;"/>
          </label>
        </div>

        <div style="display: inline; margin-right: 10px">
          <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="notSale" true-value="Y"
                 false-value="N" v-model="multi"/>
          <label for="notSale">
            <img :src="require('@/assets/mcimages/marker_not_sale@3x.png')"
                 class="form-check-label"
                 style="height: 16px;position: relative;top: -1.2px;"/>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">
        한줄글
      </label>
      <div class="col-sm-10">
        <input class="form-control" placeholder="한줄글을 입력해주세요." v-model="comment"/>
      </div>
    </div>
  </b-modal>
</template>

<script>
import SrchBarcodeList from "@/views/(duplicate)/event/Modules/srchBarcodeList.vue";
import {evtProdAction, evtProdMutations, evtProdStore} from "@/state/modules/eventProductStoreHelper";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgModalOptCenter} from "@/components/globalFunctions";
// import inputConditions from "@/plugins/globalConditionFilter";

export default {
  name: "registerSingleProdModal",

  components: {SrchBarcodeList},

  props: {
    evlData: {
      type: Object,
    }
  },

  data() {
    return {
      srchCode: '',
      originPrice: '',
      salePrice: '',
      comment: '',
      limit: 'N',
      manager: 'N',
      multi: 'N'
    }
  },

  computed: {
    ...evtProdStore,
    ...loggedInfoObject
  },

  mounted() {
    console.log('srchCode :', this.evlData)
  },

  methods: {
    ...evtProdMutations,
    ...evtProdAction,

    barcodeSrch() {
      this.callBarcodeListSrch(this.srchCode)
    },

    addSingleProd(bv) {
      const url = `/api/event/group/product/${this.logged_info.biznum}`

      let param = {
        product_name: this.selectedBarcode.product_name_doha,
        barcode: this.selectedBarcode.barcode,
        origin_price: this.originPrice,
        sale_price: this.salePrice,
        comment: this.comment,
        pos_bg_code: this.evlData.pos_bg_code,
        badge_limit: this.limit,
        badge_manager: this.manager,
        badge_multi: this.multi
      }

      if (param.barcode === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('바코드를 검색하여 상품을 선택해주세요.', msgModalOptCenter)
      } else if (this.originPrice === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('원가격을 확인해주세요.', msgModalOptCenter)
      } else if (this.salePrice === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('할인가격을 확인해주세요.', msgModalOptCenter)
      } else if (parseInt(this.originPrice) - parseInt(this.salePrice) < 0) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('원가격과 할인가격을 확인해주세요.', msgModalOptCenter)
      }

      axios.post(url, param)
          .then((res) => {
            console.log(res.data)

            if (res.data.status === 'fail') {
              if (res.data.msg === 'Duplicate') {
                this.$bvModal.msgBoxOk(`이미 등록된 상품입니다.`, msgModalOptCenter)
              }
            } else {
              this.$emit('childCallProdListUpdate', this.evlData.pos_bg_code)
            }
          })
          .catch(
              err => console.log('add Single Prod Error :', err)
          )
    },

    fieldInit() {
      console.log('CAncel')
      this.srchCode = ''
      this.originPrice = ''
      this.salePrice = ''
      this.comment = ''
      this.limit = 'N'
      this.manager = 'N'
      this.multi = 'N'
      this.setBarcodeData({})
    }
  }
}
</script>

<style scoped>

</style>
